import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { withRouter } from "../withRouter";
import {
  getEvent,
  getEventColors,
  getEventStatements,
  getProjectColors,
  getProjectStatements,
  getProjectVoteStats,
  getVoteStats,
} from "../api";
import { BarChartFill, PersonLinesFill, Square } from "react-bootstrap-icons";
import BarGraph from "./BarGraph";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import PresenterNavBar from "./PresenterNavBar";
import PresenterFooter from "./PresenterFooter";
import bandesPhovia from "../assets/bandes_phovia.svg";
import { withTheme } from "../withTheme";
import { BASE_URL } from "../config";
import LoadingButton from "./LoadingButton";
import { Spring, animated } from "@react-spring/web";
import html2canvas from "html2canvas";
import { Button } from "react-bootstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";

class PresenterViews extends Component {
  state = {
    event: null,
    currentStatement: null,
    loading: false,
    error_message: "",
    error: false,
    nbVotes: 0,
    statement_order: 0,
  };
  intervalId = null; // This will store the interval ID
  myRef = React.createRef();

  formatStatement(statement) {
    return statement.split("<br/>").map((text, index, array) => (
      <>
        {text}
        {/* Render a <br /> for all but the last segment */}
        {index === array.length - 1 ? null : <br />}
      </>
    ));
  }
  exportToImage = () => {
    const images = [];
    const zip = new JSZip();

    const exportStatements = async (i) => {
      if (i < this.state.statements.length) {
        let results;
        if (this.props.level === "project") {
          results = await getProjectVoteStats(
            this.props.params.project_id,
            this.state.statements[i].id
          );
        } else {
          results = await getVoteStats(
            this.props.params.event_id,
            this.state.statements[i].id
          );
        }
        this.setState(
          {
            results,
            statement_order: i,
            currentStatement: this.state.statements[i],
          },
          () => {
            // Wait for a moment to allow the component to re-render
            setTimeout(() => {
              html2canvas(this.myRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const imgDataBlob = atob(imgData.split(",")[1]);
                const imgAsArray = [];

                for (let i = 0; i < imgDataBlob.length; i++) {
                  imgAsArray.push(imgDataBlob.charCodeAt(i));
                }

                const imgAsBlob = new Blob([new Uint8Array(imgAsArray)], {
                  type: "image/png",
                });

                zip.file(`image${i}.png`, imgAsBlob, { binary: true });

                // Move on to the next statement
                exportStatements(i + 1);
              });
            }, 2000); // Adjust this delay as needed
          }
        );
      } else {
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, "images.zip");
        });
      }
    };

    exportStatements(0);
  };
  handleError(e) {
    let error_message = "";
    console.log("error", e);

    if (e.response) {
      switch (e.response.status) {
        case 400:
          // Redirect to another page if the error is 400
          this.props.navigate("/admin");
          return; // exit from the function
        case 401:
          this.props.navigate("/admin");
          return; // or other specific error codes
        case 403:
          this.props.navigate("/admin");
          return; // or other specific error codes
        case 404:
          // Display an alert if error is 401 or 404
          error_message = e.response.data.detail || "An error occurred.";
          break;
        default:
          // Display the error detail from the server
          error_message =
            e.response.data.detail || "An unexpected error occurred.";
          break;
      }
    } else if (e.message && e.message === "No token found") {
      this.props.navigate("/admin");
      return;
    } else {
      // Handle the scenario where there's no response (likely a network/connection error)
      error_message = "A connection error occurred. Please try again later.";
    }

    // Set the state to reflect the error
    this.setState({ error: true, error_message, loading: false });
  }

  renderConsensus() {
    if (
      this.state.results.percentage_votes_gte_7 >= 75 &&
      this.state.results.median >= 8
    )
      return (
        <div
          className="p-3"
          style={{
            backgroundColor: "rgba(52, 172, 74, 1)",
            borderRadius: "10px",
          }}
        >
          <span
            className="fs-3 fw-bold text-align-center"
            style={{ color: "var(--light)" }}
          >
            Strong consensus
          </span>
        </div>
      );
    if (
      this.state.results.percentage_votes_gte_7 >= 75 ||
      this.state.results.median >= 8
    )
      return (
        <div
          className="p-3"
          style={{
            backgroundColor: "#D1E7DD",
            borderRadius: "10px",
          }}
        >
          <span
            className="fs-3 fw-bold text-align-center"
            style={{ color: "var(--dark)" }}
          >
            Good consensus
          </span>
        </div>
      );

    return (
      <div
        className="p-3"
        style={{
          backgroundColor: "#E2E2E2",
          borderRadius: "10px",
        }}
      >
        <span
          className="fs-3 fw-bold text-align-center"
          style={{ color: "var(--dark)" }}
        >
          Absence of consensus
        </span>
      </div>
    );
  }

  renderButton() {
    const lang = this.state.event?.language;
    const translations = {
      en: {
        prev: "Prev",
        next: "Next",
      },
      fr: {
        next: "Suivant",
        prev: "Précédent",
      },
    };
    const t = translations[lang == undefined || lang == null ? "fr" : lang];
    return (
      <div style={{ display: "flex" }}>
        {this.state.statement_order > 0 && (
          <LoadingButton
            variant="outline-dark me-1"
            onClick={this.prevStatement.bind(this)}
          >
            {t.prev}
          </LoadingButton>
        )}
        {this.state.statement_order < this.state.statements.length - 1 && (
          <LoadingButton
            variant="outline-dark ms-1"
            onClick={this.nextStatement.bind(this)}
          >
            {t.next}
          </LoadingButton>
        )}
      </div>
    );
  }

  async nextStatement() {
    this.setState({ loading: true });
    try {
      const nextStatementOrder = this.state.statement_order + 1;
      const nextStatement = this.state.statements[nextStatementOrder];

      if (!nextStatement) {
        throw new Error("No more statements");
      }
      let results;
      if (this.props.level === "project") {
        results = await getProjectVoteStats(
          this.props.params.project_id,
          nextStatement.id
        );
      } else {
        results = await getVoteStats(
          this.props.params.event_id,
          nextStatement.id
        );
      }

      this.setState({
        currentStatement: nextStatement,
        statement_order: nextStatementOrder,
        results,
        loading: false,
      });
    } catch (e) {
      this.handleError(e);
    }
  }

  async prevStatement() {
    this.setState({ loading: true });
    try {
      const prevStatementOrder = this.state.statement_order - 1;
      const prevStatement = this.state.statements[prevStatementOrder];

      if (!prevStatement) {
        throw new Error("No more statements");
      }

      let results;

      if (this.props.level === "project") {
        results = await getProjectVoteStats(
          this.props.params.project_id,
          prevStatement.id
        );
      } else {
        results = await getVoteStats(
          this.props.params.event_id,
          prevStatement.id
        );
      }

      this.setState({
        currentStatement: prevStatement,
        statement_order: prevStatementOrder,
        results,
        loading: false,
      });
    } catch (e) {
      this.handleError(e);
    }
  }
  async componentDidMount() {
    // Replace this URL with the correct endpoint URL
    this.setState({ loading: true });
    try {
      let colors;
      let event = null;
      if (this.props.level === "project") {
        colors = await getProjectColors(this.props.params.project_id);
      } else {
        event = await getEvent(this.props.params.event_id);
        colors = await getEventColors(this.props.params.event_id);
      }

      let statements;
      if (this.props.level === "project") {
        statements = await getProjectStatements(this.props.params.project_id);
        statements.sort((a, b) => a.order_number - b.order_number);
      } else {
        statements = await getEventStatements(this.props.params.event_id);
        statements.sort((a, b) => a.order_number - b.order_number);
      }

      const currentStatement = statements[this.state.statement_order];

      let results;

      if (this.props.level === "project") {
        results = await getProjectVoteStats(
          this.props.params.project_id,
          currentStatement.id
        );
      } else {
        results = await getVoteStats(
          this.props.params.event_id,
          currentStatement.id
        );
      }

      this.setState({
        event,
        statements,
        currentStatement,
        results,
        colors,
        loading: false,
      });
    } catch (e) {
      this.handleError(e);
    }
  }

  render() {
    const lang = this.state.event?.language;
    const translations = {
      en: {
        statementToVote: "Statement to vote",
        resultsOfVoting: "Results of voting",
        voters: "voters",
        voter: "voter",
        noOpinion: "no opinion",
        scores: "scores",
        median: "median",
      },
      fr: {
        statementToVote: "Assertion",
        resultsOfVoting: "Résultats du vote",
        voters: "votants",
        voter: "votant",
        noOpinion: "sans opinion",
        scores: "scores",
        median: "médiane",
      },
    };
    const t = translations[lang == undefined || lang == null ? "fr" : lang];

    return (
      <div
        onDoubleClick={this.exportToImage}
        ref={this.myRef}
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: this.state.colors?.background_color,
          overflowY: "hidden",
        }}
      >
        <img
          src={
            this.state.event
              ? BASE_URL +
                "/events/" +
                this.state.event.id +
                "/data/presenter_background"
              : null
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = "none";
          }}
          alt=""
          style={{
            position: "absolute", // Absolute positioning
            top: 0, // Align to the top
            left: "5%", // Align to the left
            // Full width
            height: "100%", // Full height
            objectFit: "fill", // Forces the content to fill the container, ignoring aspect ratio
          }}
        />
        <PresenterNavBar
          goBack={true}
          backgroundColor={this.state.colors?.header_background_color}
          text={
            this.state.currentStatement
              ? "DELPHI VOTING - " + this.state.currentStatement.session_name
              : "DELPHI VOTING"
          }
        ></PresenterNavBar>

        <Container
          fluid
          className="p-3 px-5"
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            flexDirection: "column",
            overflowY: "hidden", // Prevent scrolling within the main content
            position: "relative",
          }}
        >
          <Alert show={this.state.error} variant={"danger"}>
            <Alert.Heading>Event not found</Alert.Heading>
            <p>{this.state.error_message}</p>
          </Alert>
          {this.state.currentStatement && (
            <div
              style={{
                flex: 1,
                display: "flex",
                height: "100%",
              }}
            >
              <div
                className="pb-3"
                style={{
                  display: "flex",
                  flexGrow: 1,

                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    className="p-2"
                    style={{
                      backgroundColor: this.state.colors?.background_color,
                      borderRadius: "10px",
                    }}
                  >
                    {t.statementToVote}
                  </h3>
                  <Spring
                    key={this.state.currentStatement.id} // Add this line
                    from={{ opacity: 0, transform: "scale(0.8)" }}
                    to={{ opacity: 1, transform: "scale(1)" }}
                  >
                    {(props) => (
                      <animated.div
                        style={{
                          ...props,
                          display: "flex",
                          borderRadius: "15px",
                          alignItems: "center",
                          backgroundColor:
                            this.state.colors?.statement_background_color,
                          boxShadow: "0px 2px 20px -10px rgba(0,0,0,0.2)",
                        }}
                        className="p-3 mb-3"
                      >
                        <div
                          style={{
                            display: "flex",
                            height: "3.5em",
                            aspectRatio: 1,
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="bg-light me-3"
                        >
                          <span className="fs-4 fw-bolder">
                            #{this.state.currentStatement.order_number}
                          </span>
                        </div>

                        <span className="fs-5 fw-bold text-light">
                          {this.formatStatement(
                            this.state.currentStatement.statement_string
                          )}
                        </span>
                      </animated.div>
                    )}
                  </Spring>
                  {this.state.loading && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  )}

                  {!this.state.loading && (
                    <Row
                      style={{
                        display: "flex",
                        flex: 1,
                        height: "100%",
                      }}
                    >
                      <Col md={2}>
                        <div></div>
                      </Col>
                      <Col
                        md={10}
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "100%",
                        }}
                      >
                        <div
                          className="p-3"
                          style={{
                            display: "flex",
                            flex: 1,
                            height: "100%",

                            flexDirection: "column",
                            justifyContent: "space-around",
                            backgroundColor:
                              this.state.colors?.background_color,
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            className="mb-3"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <BarChartFill
                                className="me-2"
                                size={24}
                              ></BarChartFill>
                              <span className="fs-4 fw-bolder">
                                {t.resultsOfVoting}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="px-3 ms-3"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  backgroundColor: "var(--light)",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                              >
                                <PersonLinesFill
                                  className="me-2"
                                  size={24}
                                ></PersonLinesFill>
                                <span className="fs-4 fw-bolder">
                                  {this.state.results.total_votes}{" "}
                                  {this.state.results.total_votes > 1
                                    ? t.voters
                                    : t.voter}
                                </span>
                              </div>
                              {this.state.currentStatement.version > 1 && (
                                <div
                                  className="px-3 ms-3"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "var(--light)",
                                    alignItems: "center",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span className="fs-4 fw-bolder">
                                    Version{" "}
                                    {this.state.currentStatement.version}
                                  </span>
                                </div>
                              )}
                              {this.state.results.votes_null > 0 && (
                                <div
                                  className="px-3 ms-3"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "var(--light)",
                                    alignItems: "center",
                                    opacity: 0.5,
                                    borderRadius: "10px",
                                  }}
                                >
                                  <Square className="me-2" size={24}></Square>
                                  <span className="fs-4 fw-bolder">
                                    {this.state.results.votes_null}{" "}
                                    {t.noOpinion}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <Container
                            style={{
                              flex: 1,
                              height: "100%",
                            }}
                          >
                            <Row
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                height: "100%",

                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Col
                                md={4}
                                xs={12}
                                className="me-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100%",
                                }}
                              >
                                <BarGraph
                                  percentageVotes={
                                    this.state.results.percentage_votes
                                  }
                                />
                              </Col>
                              <Col
                                md={4}
                                xs={12}
                                className="me-3"
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      display: "flex",

                                      height: "75px",
                                      width: "75px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "var(--light)",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <span className="fs-4 fw-bold">
                                      {this.state.results.percentage_votes_gte_7.toFixed(
                                        0
                                      )}{" "}
                                      %
                                    </span>
                                  </div>
                                  <hr
                                    style={{ width: "100%", height: "1px" }}
                                  ></hr>
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "75px",
                                      width: "75px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "10px",
                                      backgroundColor: "var(--light)",
                                    }}
                                  >
                                    <span className="fs-4 fw-bold">
                                      {this.state.results.median.toFixed(1)}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",

                                    flex: 1,
                                  }}
                                >
                                  <div
                                    className="mb-3"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",

                                      alignItems: "center",
                                      flex: 1,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        height: "75px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <div
                                        className="border p-1"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          borderRadius: "10px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {Array.from(
                                          { length: 3 },
                                          (_, index) => (
                                            <div
                                              className="fs-4 fw-bold m-1"
                                              key={index}
                                              style={{
                                                width: "26px",
                                                height: "26px",
                                                borderRadius: "13px",
                                                display: "flex",
                                                alignItems: "center",
                                                color: "var(--dark)",
                                                backgroundColor: "var(--light)",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {index + 7}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    <div>
                                      <span className="fs-4 fw-bold">
                                        {t.scores}
                                      </span>
                                    </div>
                                  </div>
                                  <hr
                                    style={{ width: "100%", height: "1px" }}
                                  ></hr>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width: "100%",
                                      flex: 1,
                                    }}
                                  >
                                    <span className="fs-4 fw-bold">
                                      {t.median}
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={4}
                                xs={12}
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  height: "100px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {this.renderConsensus()}
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
                {this.renderButton()}
              </div>
            </div>
          )}
        </Container>

        {/* <PresenterFooter
          img={
            this.state.event
              ? BASE_URL + '/events/' + this.state.event.id + '/logo'
              : null
          }
        ></PresenterFooter> */}
      </div>
    );
  }
}

export default withRouter(withTheme(PresenterViews));
